.player {
  position: relative; /* Ensure we can position context menu inside */
  width: 100%; /* Fill the width of its parent */
  height: 100%; /* Fill the height of its parent */
  overflow: hidden; /* Clip any child contents that go outside this container */
}

.react-player {
  position: relative; 
  width: 100%; 
  height: 100%; 
  video {
    object-fit: contain; /* This ensures video content scales correctly */
  }
}
