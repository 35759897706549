.send-ticket-container {
    margin: 2rem auto;
    padding: 2rem;
    width: 60%;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.send-ticket-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #e0e0e0;
}

.send-ticket-container form > div {
    margin-bottom: 1rem;
}

.send-ticket-container label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #e0e0e0;
}

.send-ticket-container textarea,
.send-ticket-container input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.send-ticket-container button {
    display: block;
    margin: 1rem auto;
    padding: 0.5rem 1.5rem;
    background-color: rgb(238, 77, 58);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.send-ticket-container button:hover {
    background-color: rgb(238, 67, 48);
}

.send-ticket-container p {
    text-align: center;
    color: #e0e0e0;
}
