/* Recordings.css */

.Recordings {
    margin: 20px auto;
    max-width: 800px;
    padding: 20px;
    background-color: #000; /* Black background */
    color: #fff; /* White text color */
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.PageTitle {
    font-size: 24px;
    margin-bottom: 10px;
}

.RecordingGroups {
    display: flex;
    flex-direction: column;
}

.RecordingGroup {
    margin-top: 20px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white background */
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.RecordingDate {
    font-size: 18px;
    margin-bottom: 10px;
}

.RecordingList {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
