.profile-table {
    border-radius: 0.5rem;
    border: 2px solid var(--borderline-color);
    width: 60vw;
}

.profile-table-title {
    padding: 1rem;
    text-decoration: underline;
    border-bottom: 2px solid var(--borderline-color);
}

.profile-table-entry {
    padding: 1rem;
}