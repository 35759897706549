.profile-page {
  position: relative;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  padding-top: 1.5rem;
  color: white;
}

.profile-container {
  h1, h2, p {
    font-family: Roboto, sans-serif;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
}