/* Container Styles */
div {
    font-family: Arial, sans-serif;
}

/* Ticket Container */
.ticket-container {
    margin: 1rem auto;
    padding: 1rem;
    width: 80%;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

.ticket-container:hover {
    background-color: #f0f0f0;
}

/* Ticket Details */
.ticket-title {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 0.5rem;
}

.ticket-description {
    margin-bottom: 0.5rem;
}

.ticket-from,
.ticket-email,
.ticket-date {
    font-size: 0.9em;
    color: #777;
}


