body {
  font-family: 'Arial', sans-serif; /* You can also import fonts from Google Fonts for more customizability */
}

.signUpBTN {
  display: flex;
  justify-content: center;
  font-size: 1em;
  color: white;
  border: none;
  padding: 18px;
  border-radius: 16px;
  cursor: pointer;
  background-color: rgb(238, 77, 58);
  background-image: linear-gradient(to bottom, rgb(238, 77, 58), rgb(238, 67, 48));
  transition: background 0.3s;
  outline: none; /* Removes the default focus outline */
}

.signUpBTN:hover {
  background-image: linear-gradient(to bottom, rgb(238, 87, 68), rgb(238, 57, 38));
}

.signUpBTN span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.signUpBTN span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.signUpBTN:hover span {
  padding-right: 25px;
}

.signUpBTN:hover span:after {
  opacity: 1;
  right: 0;
}

.signupTitle {
  padding: 1em 0;
  font-size: 2em;
  text-align: center;
  color: #fff7f7;
}

.sign-up-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* This will make it center vertically */
}
