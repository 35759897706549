*{
    margin: 0;
    padding: 0;

}
.videos {
    background-color: var(--main-bg-color);
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 50px);
    height: 100vh;
}

.buttons {
    border-bottom: 2px solid var(--borderline-color);
}

.video-grid {
    display: grid;
    grid-gap: 10px;
    padding: 10px;
    flex-grow: 1; /* Ensure that this takes up all remaining space */
    overflow: auto; /* Enable scroll if the videos list grows long */
}

/* 2x2 layout */
.video-grid.two-by-two {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

/* 1 left 3 right layout */
.video-grid.left-one-right-three {
    grid-template-columns: 2fr 1fr; /* The left video takes up twice the width of each right video */
    grid-template-rows: repeat(3, 1fr); /* Split the height into three equal parts for the right videos */
}

/* Style for the main video on the left */

.video-grid.left-one-right-three .player:nth-child(1) {
    grid-row: 1 / span 3; /* The main video spans all 3 rows */
    grid-column: 1; 
}

/* Styles for the three videos on the right */

.video-grid.left-one-right-three .player:nth-child(n+2) {
    grid-column: 2;
    /* No need to set grid-row as they'll automatically occupy one row each */
}

.video-grid.top-two-bottom-four {
    grid-template-columns: repeat(4, 1fr); /* Four columns in total to accommodate the maximum videos in a row */
    grid-template-rows: 2fr 1fr; /* The top row will be twice as tall as the bottom ones */
}

/* Style for the two videos on the top row */

.video-grid.top-two-bottom-four .player:nth-child(1),
.video-grid.top-two-bottom-four .player:nth-child(2) {
    grid-column: span 2; /* Each of these videos spans two columns */
    grid-row: 1; 
}

/* Styles for the four videos on the bottom row */

.video-grid.top-two-bottom-four .player:nth-child(n+3) {
    grid-row: 2;
    /* No need to set grid-column as they'll automatically occupy one column each */
}

/* 3x3 layout */
.video-grid.three-by-three {
    grid-template-columns: repeat(3, 1fr); /* Three equal-width columns */
    grid-template-rows: repeat(3, 1fr);   /* Three equal-height rows */
}
