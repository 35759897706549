.logo-btn {
    cursor: pointer;
    appearance: none;
    border: 0;
    padding: 0;
    vertical-align: middle;
    background-color: inherit;
}

.logo-btn img {
    display: block;
    height: 48px;
    width: auto;
    padding-left: 5px;
}