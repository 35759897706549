.sign-in-container {
  img {
    position: absolute;
    top: 8px;
    left: 16px;
    width: 20em;
    // height: 8em;
  }

  p {
    margin-top: 5em;
  }

  a {
    color: orange;
    text-decoration: none;
    position: relative;
  }

  a:hover {
    color: orange;
  }

  a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -3px;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: orange;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  a:hover:after {
    width: 100%;
    left: 0;
  }

  h3 {
    text-align: left;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
  }

  input {
    position: relative;
    text-align: center;
    padding: 0.5rem 0;
    margin: 1.2rem 0;
    border-radius: 6px;
    border: none;
    width: 25em;
    height: 3.2em;
    border-style: solid;
    border-width: thin;
    font-size: 16px;

  }
}

.login {
  font-size: 1em;
  color: white;
  border: none;
  display: flex;
  padding: 18px;
  border-radius: 16px;
  cursor: pointer;
  margin: 0;
  position: absolute;
  background-color: rgb(238, 77, 58);
}

.login span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.login span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.login:hover span {
  padding-right: 25px;
}

.login:hover span:after {
  opacity: 1;
  right: 0;
}

.sign-in-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding-bottom: 4em;
  padding-top: 14em;
  width: 30em;
  margin: auto;
  margin-top: 10em;
  margin-bottom: 8em;
}



.signupDiv {
  display: flex;
}

#signUp {
  color: white;
  font-family: 'Roboto', sans-serif;
  height: auto;
  margin-top: 1.5em;
  margin-left: 5em;
}

.signup {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1em;
  color: white;
  border: none;
  display: flex;
  padding: 6px;
  background-color: rgb(40, 41, 44);
  border-radius: 6px;
  cursor: pointer;
  background-color: none;
  margin-top: 1em;
}

.signup :hover {
  text-decoration: underline;
}