.notfound-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.notfound-container {
    h1 {
        color: var(--main-theme-color);
    }
    h2 {
        color: var(--main-theme-color);
        margin-bottom: 50px;
    }
    a{
        width: fit-content;
        padding: 10px 20px;
        color: var(--secondary-theme-color);
    }
    a:after {
        background-color: var(--secondary-theme-color);
    }
}