.homepage-content {
    display: flex;
    /* TODO: set the height dynamically, instead of hard coded*/
    height: calc(100vh - 50px);
    /* full screen height minus the navbar height */
    overflow: hidden;
}

/* Ensure the sidebar does not grow or shrink and stays at a fixed width */
.sidebar {
    flex: 0 0 auto;
    /* Assuming a width of 250px for the sidebar */
    overflow-y: auto;
}

.videos {
    flex-grow: 1;
    /* overflow-y: auto; */
}
